/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import CameraIcon from '../../assets/camera.svg';

import styles from './slider.module.scss';

const Slider = () => {
  const { Hls } = window;

  const video = document.getElementById('video');
  const videoSrc =
    'https://hoktastream4.webcamera.pl/kotelnica_cam_0b240f/kotelnica_cam_0b240f.stream/playlist.m3u8';
  if (Hls.isSupported()) {
    const hls = new Hls();
    hls.loadSource(videoSrc);
    hls.attachMedia(video);
  } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    video.src = videoSrc;
  }

  return (
    <div className={styles.container}>
      <div className={styles.slider}>
        <div className={styles.camera}>
          <video className={styles.camera__player} id="video" autoPlay controls={false} />
          <div className={styles.camera__name}>
            <div className={styles.camera__box}>
              <img className={styles.camera__icon} src={CameraIcon} alt="Icon camera" />
              <h2 className={styles.camera__title}>Kotelnica Białczańska</h2>
            </div>
            <div className={styles.camera__box}>
              <div className={styles.camera__dot} />
              <p className={styles.camera__text}>Online</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
